/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I joined \"Nights and Weekends,\" a course by buildspace, aimed at helping individuals complete their side projects. The weekly lectures provided practical skills and a supportive community, crucial for developing my project idea into a reality."), "\n", React.createElement(_components.p, null, "My project involved creating a no-code platform that allowed users to integrate APIs and services into their websites without coding. The goal was to make web development accessible to everyone, regardless of their technical skills. The platform focused on simplicity and ease of use, enabling users to build complex workflows intuitively."), "\n", React.createElement(_components.p, null, "In building this project, I used a monorepo approach with about 15 packages, which facilitated code reusability and efficiency. The API utilized serverless architecture to minimize costs and server management. In hindsight, using an existing admin dashboard component library and upgrading to Dalle-3 would have been more efficient. Additionally, using a pre-built library for website layouts could have streamlined the design process, focusing more on the core functionality of the project."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
